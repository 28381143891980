body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code,
kbd,
samp,
pre {
  font-family: "Inter", sans-serif !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src: url("./assets/fonts/Inter-Thin.ttf") format("embedded-opentype");
  font-weight: thin;
  font-style: thin;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src: url("./assets/fonts/Inter-Light.ttf") format("embedded-opentype");
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src: url("./assets/fonts/Inter-Regular.ttf") format("embedded-opentype");
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src: url("./assets/fonts/Inter-Medium.ttf") format("embedded-opentype");
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("embedded-opentype");
  font-weight: 600;
  font-style: 600;
}

.slick-track {
  height: auto !important;
}

.react-photo-gallery--gallery img {
  border-radius: 20px !important;
}

.headergradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #ffffff;
}

.floating-label-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 4px;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  transition: all 0.1s ease;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: 40%;
  transform: translateY(-50%);
  bottom: 0px;
  left: 16px;
  opacity: 1;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}
input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .block {
  background-color: #27ae60;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .block {
  background-color: #27ae60;
}
input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
